import React from 'react';
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImg from "../../images/no-image.png"

const HolidayPropertyCard = (props) => {
    return (
        <div className="property-card-wrapper holiday-homes">
            <div className="property-card-img-zoom">
                {
                    props?.img && props?.img.length > 0 ?
                    <Link to={props.propertyDetailsLink}>
                        {props.normalImg ? <img src={props?.img[0].url} alt={props.propertyAddress + " - White & Co Real Estate"} />
                        :
                        <ShowProcessedImage
                            images={props?.img && props?.img[0]}
                            attr={{
                                className: "img-fluid",
                                alt: props.propertyAddress + " - White & Co Real Estate",
                            }}
                            transforms={imageConfig.property.searchResults.sizes}
                        />}
                    </Link>
                    :
                    <Link to={props.propertyDetailsLink}>
                        <img src={NoImg} alt="no image" />
                    </Link>
                }
            </div>
            <div className="property-card-text-wrapper">
                {
                    props.propertyDescription &&
                    <p className="display-address display-desc">{props.propertyDescription}</p>
                }
                {
                    props.propertyPrice &&
                    <div className="price-details">{props.propertyPrice.toLocaleString()} {props.propertyCurrency} <span className="holiday-night">per month</span></div>
                }
                {
                    props.propertyAddress &&
                    <div className="location-details">{props.propertyAddress}</div>
                }
                <ul className="list-inline off-plan-contact-list d-flex align-items-center">
                    {
                        props.propertyBedroom > 0 &&
                        <li className="list-inline-item">
                            <div className="d-flex align-items-center">
                                <i className="icon icon-property-bedroom"></i>
                                <span>{props.propertyBedroom} {props.propertyBedroom > 1 ? "bedrooms" : "bedroom"}</span>
                            </div>
                        </li>
                    }
                    {
                        props.propertyBathroom > 0 &&
                        <li className="list-inline-item">
                            <div className="d-flex align-items-center">
                                <i className="icon icon-property-bathroom"></i>
                                <span>{props.propertyBathroom} {props.propertyBathroom > 1 ? "bathrooms" : "bathroom"}</span>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}

export default HolidayPropertyCard