import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { NoResultProperties } from '../../queries/common_use_query';
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImg from "../../images/no-image.png"

const HolidayNoResult = (props) => {

    const[department, setDepartment] = useState('residential');

    useEffect(()=>{
        props.department && setDepartment(props.department);
    },[props.department]);

    const {loading:loading, error:error, data:noResultdata} = NoResultProperties(props.status, department, props.searchType);

    return (
        <React.Fragment>
            {
                noResultdata && noResultdata.properties.length > 0 &&
                noResultdata.properties.map((hit, index) => {

                    // property details url structure
                    let uriStr = "";
                    
                    if (hit.search_type === "sales" && hit.department === 'short_lets') {
                        uriStr = `holiday-homes-for-sale/`
                    } else if (hit.search_type === "lettings" && hit.department === 'short_lets') {
                        uriStr = `holiday-homes-for-rent/`
                    }
                    // property details url structure

                    let processedImages = JSON.stringify({});
                    if (hit?.imagetransforms?.images_Transforms) {
                      processedImages = hit.imagetransforms.images_Transforms;
                    }

                    return (
                        <div className="property-card-wrapper holiday-homes">
                            <div className="property-card-img-zoom">
                                {
                                    hit?.images && hit?.images.length > 0 ?
                                    <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                                        <img src={hit?.images && hit?.images[0].url} alt={hit.display_address} />
                                    </Link>
                                    :
                                    <Link to={`/${uriStr}${hit.slug}-${hit.id}/`}>
                                        <img src={NoImg} alt="no image" />
                                    </Link>
                                }
                            </div>
                            <div className="property-card-text-wrapper">
                                {
                                    hit.price &&
                                    <div className="price-details">{hit.price.toLocaleString()} {"AED"} <span className="holiday-night">per month</span></div>
                                }
                                {
                                    hit.display_address &&
                                    <div className="location-details">{hit.display_address}</div>
                                }
                                <ul className="list-inline off-plan-contact-list d-flex align-items-center">
                                    {
                                        hit.bedroom > 0 &&
                                        <li className="list-inline-item">
                                            <div className="d-flex align-items-center">
                                                <i className="icon icon-property-bedroom"></i>
                                                <span>{hit.bedroom} {hit.bedroom > 1 ? "bedrooms" : "bedroom"}</span>
                                            </div>
                                        </li>
                                    }
                                    {
                                        hit.bathroom > 0 &&
                                        <li className="list-inline-item">
                                            <div className="d-flex align-items-center">
                                                <i className="icon icon-property-bathroom"></i>
                                                <span>{hit.bathroom} {hit.bathroom > 1 ? "bathrooms" : "bathroom"}</span>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default HolidayNoResult